<template>
  <div>
    <div class="" v-if="type === 'gps'">
      <div class="geo-button-wrapper">
        <el-button
          type="danger"
          @click="getLocation"
          v-if="!gettingLocation && !geoActive"
        >
          Activar geolocalización
        </el-button>
        <el-button
          type="danger"
          disabled
          v-if="gettingLocation && !geoActive"
        >
          Procesando...
        </el-button>
      </div>
      <div class="geo-message-wrapper">
        <transition-group name="fade">
          <p v-if="geoError" key="error">{{geoError}}</p>
          <h2 v-if="geoActive" key="success">¡Listo!</h2>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import Screen from 'Components/questions/Screen'
import csc from 'country-state-city'
import Vue from 'vue'

export default {
  name: 'LocationSelector',

  props: [ 'data' ],

  components: {
  },

  data () {
    return {
      clearTimeout: null,
      fieldValue: '',
      geolocation: {},
      gettingLocation: false,
      geoGPS: {},
      geoActive: false,
      geoError: '',
      countriesList: {
        "Perú": 'Peru',
        "República Dominicana": 'Dominican Republic',
        "Panamá": 'Panama',
        "México": 'Mexico',
        "Canadá": 'Canada',
        "Estados Unidos": 'United States',
        "Brasil": "Brazil",
        "España": "Spain"
      }
    }
  },

  computed: {
    type () {
      var locationSettings = this.data.fields.locationSettings
      if (locationSettings) {
        if (locationSettings.includes('GPS')) {
          return 'gps'
        }
        if (locationSettings.includes('Country')) {
          return 'country'
        }
        if (locationSettings.includes('State')) {
          return 'state'
        }
        if (locationSettings.includes('City')) {
          return 'city'
        }
        if (locationSettings.includes('Town')) {
          return 'town'
        }
      }
    }
  },

  mounted () {
    if (this.data.fields.locationSettings && this.data.fields.locationSettings.includes('Geolocation')) {
      Vue.axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.VUE_APP_GEOCODING_API}`)
        .then(data => {
          this.geolocation = {
            type: "Point",
            coordinates: [ data.data.location.lng, data.data.location.lat ],
          }
          this.$store.commit('STORE_GEOLOCATION', this.geolocation)
          this.$store.commit('STORE_GEOLOCATION_ACCURACY', data.data.accuracy)
        })
        .catch(err => {
          console.log('error: ', err)
        })
    }
  },

  methods: {
    getLocation() {
      this.gettingLocation = true
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( (position) => {
          this.geoActive = true
          this.geoError = ''
          this.geolocation = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude] 
          };
          this.$store.commit('STORE_GEOLOCATION', this.geolocation)
          this.$store.commit('STORE_GEOLOCATION_ACCURACY', 0)
        }, () => {
          this.handleLocationError(`Debe aceptar compartir su ubicación. En la configuración del sitio en su navegador, habilite la ubicación para ${process.env.VUE_APP_BASE_URL} y refresque la página para poder continuar.`);
        });
      } else {
        this.handleLocationError('Su navegador no parece tener soporte de geolocalización. Intente con otro dispositivo.');
      }
    },    
    handleLocationError(message) {
      this.geoError = message
    },
    distance (lat1, lon1, lat2, lon2, unit) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
      }
      else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
      }
    },
    storeLocation () {
      switch(this.type) {
        case 'country': 
          this.storeCountry()
        break
        case 'state':
          this.storeState()
        break
        case 'city':
          this.storeCity()
        break
        case 'town':
          this.storeTown()
      }
    },
    storeCountry () {
      var country = csc.getAllCountries().filter(item => {
        return item.name === this.fieldValue || item.name === this.countriesList[this.fieldValue]
      })
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'country', value: country[0] })
    },
    storeState () {
      var state = this.statesList.filter(item => {
        return item.name === this.fieldValue
      })
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'state', value: state[0] })
    },
    storeCity () {
      var city = this.citiesList.filter(item => {
        return item.name === this.fieldValue
      })
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'city', value: city[0] })
      if (city[0] && city[0].location && this.geolocation.coordinates) {
        var d = this.distance(city[0].location.coordinates[1], city[0].location.coordinates[0], this.geolocation.coordinates[1], this.geolocation.coordinates[0], 'K')
        this.$store.commit('STORE_GEOLOCATION_DISTANCE', d)
      }
      if (city[0] && city[0].location) {
        this.$store.commit('STORE_SELF_GEOLOCATION', city[0].location)
      }
    },
    storeTown () {
      this.$store.commit('STORE_LOCATION_ITEM', { field: 'town', value: this.fieldValue })
    }
  },

  watch: {
    geoActive: function (newValue) {
      setTimeout(() => {
        this.fieldValue = this.newValue
        this.emitSelect(this.newValue)
      }, 1000)

    }
  }
}
</script>

<style lang="scss">
.alt-option {
  margin-top: 2em;
  font-size: 16px;
  a {
    color: rgba($text-main, 0.6);
    text-decoration: none;
    border-bottom: 1px solid $gray-light;
  }
}
.geo-button-wrapper {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;  
  border: 1px solid transparent;
}
.geo-message-wrapper {
  min-height: 140px;
  margin-top: 1em;
  border: 1px solid transparent;
  width: 100%;
}
.el-select {
  width: 100%;
}

</style>
